export const environment = {
  production: true,
  BASE_URL: 'https://o1bbz5e4p4.execute-api.eu-central-1.amazonaws.com/v1',
  allaURL:'https://las.mosaic-qa.siemens-energy.cloud',
  azuread:{
    clientId: '63385377-3e7f-43ea-88d9-b2bf60b007de',
    authority: 'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477',
    redirectUri: 'https://las.mosaic-qa.siemens-energy.cloud/login',
    postLogoutRedirectUri: '/'
  }

};
